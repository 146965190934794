<template>
    <div class="no-data">
        <img src="../../assets/images/nodata.png" />
        <p>未找到该账单</p>
    </div>
</template>

<script>
    export default {
        name: 'NoData',
        data() {
            return {

            }
        }
    }
</script>

<style lang="scss" scoped>
.no-data {
    width: 100%;
    padding: 70px;
    text-align: center;
    img {
        width: 80%;
    }
    p {
        color: #666666;
        margin-top: 12px;
    }
}
</style>
