<template>
    <div class="bill-undo">
        <!-- <div class="userInfo">
            <p>
                <span>{{userInfo.userName}}</span>
                <span>{{id}}</span>
            </p>
            <p>{{userInfo.userOrgan}}</p>
        </div> -->
        <div class="bill-list-wrapper">
            <scroller :on-refresh="refresh" ref="scroll">
                <div class="bill-list">
                    <v-card
                        class="bill-list-item"
                        v-for="item in billList"
                        :key="item.uuid"
                    >
                        <v-card-text>
                            <div class="bill-info-item">
                                <span>收费机构：</span>
                                <span>{{item.schoolName}}</span>
                            </div>
                            <div class="bill-info-item">
                                <span>所属机构：</span>
                                <span>{{item.organ_name}}</span>
                            </div>
                            <div class="bill-info-item">
                                <span>学生姓名：</span>
                                <span>{{item.name}}</span>
                            </div>
                            <div class="bill-info-item">
                                <span>用户编号：</span>
                                <span>{{item.idcard}}</span>
                            </div>
<!--                            <div class="bill-info-item">-->
<!--                              <span>所在班级：</span>-->
<!--                              <span>{{item.organ_name}}</span>-->
<!--                            </div>-->
                            <div class="bill-info-item">
                                <span>收费科目：</span>
                                <span>{{item.subject_name}}</span>
                            </div>
                            <div class="bill-info-item">
                                <span>账单周期：</span>
                                <span>{{item.cycle}}</span>
                            </div>
                            <div class="bill-info-item">
                                <span>缴费金额：</span>
                                <span>{{tofixed(item.subject_price)}}元</span>
                            </div>
                            <div class="bill-info-item">
                                <span>账单说明：</span>
                                <span>{{item.feeDesc}}</span>
                            </div>
                            <div class="bill-info-item">
                              <span>缴费附言：</span>
<!--                              <span>{{item.remark}}</span>-->
                              <span><input
                                  type="text"
                                  v-model="item.remark"
                                  maxlength="100"
                              /></span>
                            </div>
                        </v-card-text>
                    </v-card>
                    <no-data v-if="noData" />
                </div>
            </scroller>
        </div>
        <div class="pay-info">
            <span>合计：￥{{tofixed(total)}}元</span>
            <v-btn
                class="pay-button"
                depressed
                color="primary"
                @click="handlePay"
            >
                立即支付
            </v-btn>
        </div>
    </div>
</template>

<script>
import {overTime} from '@/util/util';
import NoData from '@/components/no-data/no-data'
import {getPayOrders, payRemark} from '@/service/service'
export default {
    name: 'BillUndo',

    components: {
        NoData
    },

    props: {
        userInfo: {
            type: Object,
            default: () => ({})
        },
    },

    data() {
        return {
            id: '',
            billList: [],
            noData: false,
            school: '',
            organ_id: ''
        }
    },

    computed: {
        total() {
            return this.billList.reduce((prev, curr) => {
                return prev + curr.subject_price;
            }, 0)
        }
    },

    beforeMount() {
        this.id = this.$route.query.id;
        this.organ_id = '';
        this.school = localStorage.getItem('school');
        this.getData();
    },

    methods: {
        getData() {
            this.noData = false;
            this.$loading.show()
            getPayOrders({
                organ_id: this.organ_id,
                idcard: this.id,
                page: 1,
                pageSize: 10000
            }).then(res => {
                this.$loading.hide()
                if (res.data.list.length === 0) {
                    this.noData = true
                } else {
                    this.billList = res.data.list;
                }
                this.$refs.scroll.finishPullToRefresh()
                this.$emit('request', res.data.list.length)
                console.log('this.billList', this.billList)
            }).catch(err => {
                this.$loading.hide()
                this.$refs.scroll.finishPullToRefresh()
                this.$toast.error(err.msg || '请求失败')
            })
        },
        refresh() {
            this.getData()
        },
        handlePay() {
            if (!this.billList.length) {
                this.$toast.error('无待缴账单');
                return
            }
            if (overTime()) {
                this.$toast.open('您好，23:55 ~ 00:05禁止交易，请您在其他时间缴费', 3000)
                return
            }
            payRemark(this.billList).then(() => {
              console.log('save remark success')
            }).catch(err => {
              console.error(err);
            });

            const item = this.billList[0];
            const query = {
                id: this.id,
                n: item.name,
                c: item.organ_name,
                p: encodeURIComponent(this.total),
            }
            this.$router.push({
                name: 'billConfirm',
                query
            })
        },
        tofixed(v) {
            return (+v).toFixed(2)
        }
    }
}
</script>

<style lang="scss" scoped>
.userInfo {
    background: #ffffff;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 8px 20px;
    text-align: center;
    z-index: 2;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    p {
        margin: 0;
        span {
            margin: 0 8px;
        }
    }
    p:last-child {
        font-size: 14px;
        color: #999999;
    }
}
.bill-list-wrapper {
    background: #f5f5f5;
    height: calc(100% - 59px);
    position: relative;
    .bill-list {
        box-sizing: border-box;
        padding: 12px 16px;
    }
    .bill-list-item {
        margin-bottom: 12px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.pay-info {
    height: 59px;
    border-top: 1px solid #dedede;
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    span {
        font-size: 16px;
        font-weight: 600;
    }
    .pay-button {
        width: 90px;
        height: 36px;
        border-radius: 5px;
        line-height: 36px;
        text-align: center;
        user-select: none;
        font-size: 14px;
    }
}
.bill-info-item {
    margin-bottom: 4px;
    font-size: 15px;
    display: flex;
    span:first-child {
        color: #333333;
        padding-left: 16px;
        white-space: nowrap;
    }
}
</style>
