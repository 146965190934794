<template>
    <div class="bill">
        <v-tabs fixed-tabs v-model="tab" class="tabs">
            <v-tab href="#undo">待缴费<i class="has-bill" v-if="hasBill"></i></v-tab>
            <v-tab href="#done">已完成</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="tab-content">
            <v-tab-item value="undo">
                <bill-undo v-if="tab === 'undo'" class="bill-tab-item" @request="listChange" />
            </v-tab-item>
            <v-tab-item value="done">
                <bill-done v-if="tab === 'done'" class="bill-tab-item" />
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import BillUndo from './BillUndo';
import BillDone from './BillDone';
export default {
    name: 'Bill',

    components: {
        BillUndo,
        BillDone,
    },

    data() {
        return {
            tab: 'undo',
            hasBill: false
        }
    },

    beforeMount() {
        console.log('Query beforeMount', window.location.href)
        const view = this.$route.query.view;
        view && (this.tab = 'done');
    },

    methods: {
        listChange(len) {
            this.hasBill = len > 0
        }
    }
}
</script>

<style lang="scss" scoped>
.bill {
    height: 100%;
    .tab-content {
        height: calc(100% - 49px);
        & ::v-deep .v-window__container {
            height: 100%;
            .v-window-item {
                height: 100%;
            }
        }
    }
    .tabs {
        border-bottom: 1px solid #dedede;
    }
}
.bill-tab-item {
    height: 100%;
}
.has-bill {
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: red;
    position: absolute;
    right: calc(50% - 36px);
    top: calc(50% - 12px);
}
</style>