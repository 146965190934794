<template>
    <div class="bill-done-wrapper">
        <scroller
            :on-refresh="refresh"
            :on-infinite="infinite"
            :no-data-text="noDataText"
            ref="scroll"
        >
            <div class="bill-done">
                <v-card
                    class="bill-list-item"
                    v-for="item in billList"
                    :key="item.id"
                >
                    <v-card-text>
                        <div class="payed-icon">
                            <img src="../../assets/images/payed.png" alt="已缴费">
                        </div>
                        <div class="bill-info-item">
                            <span>收费机构：</span>
                            <span>{{item.schoolName}}</span>
                        </div>
                        <div class="bill-info-item">
                            <span>所属机构：</span>
                            <span>{{item.organ_name}}</span>
                        </div>
                        <div class="bill-info-item">
                            <span>学生姓名：</span>
                            <span>{{item.name}}</span>
                        </div>
                        <div class="bill-info-item">
                            <span>用户编号：</span>
                            <span>{{item.idcard}}</span>
                        </div>
                        <div class="bill-info-item">
                            <span>收费科目：</span>
                            <span>{{item.subject_name}}</span>
                        </div>
                        <div class="bill-info-item">
                            <span>账单周期：</span>
                            <span>{{item.cycle}}</span>
                        </div>
                        <div class="bill-info-item">
                            <span>缴费金额：</span>
                            <span>{{tofixed(item.subject_price)}}元</span>
                        </div>
                    </v-card-text>
                </v-card>
                <no-data v-if="noData" />
            </div>
        </scroller>
    </div>
</template>

<script>
import NoData from '@/components/no-data/no-data'
import {getPayDoneOrders} from '@/service/service'
export default {
    name: 'BillDone',

    components: {
        NoData
    },

    data() {
        return {
            id: '',
            billList: [],
            noData: false,
            busy: false,
            pageNum: 1,
            noDataText: '',
            isLast: false,
            school: '',
            organ_id: ''
        }
    },

    beforeMount() {
        this.id = this.$route.query.id;
        this.organ_id = '';
        this.school = localStorage.getItem('school');
        this.getData();
    },

    mounted() {
        this.$refs.scroll.finishInfinite(false);
    },

    methods: {
        getData(refresh = false) {
            this.noData = false
            this.pageNum === 1 && this.$loading.show()
            getPayDoneOrders({
                organ_id: this.organ_id,
                idcard: this.id,
                page: this.pageNum,
                pageSize: 10
            }).then(res => {
                this.$loading.hide();
                refresh && this.$refs.scroll.finishPullToRefresh();
                this.$refs.scroll.finishInfinite(res.data.isLastPage);
                this.isLast = res.data.isLastPage;
                if ( res.data.list.length === 0) {
                    if (this.pageNum === 1) {
                        // 第一页且没有数据
                        this.noData = true;
                        this.noDataText = ''
                    } else {
                        this.noDataText = '到底了'
                    }
                } else {
                    // 有数据
                    this.noData = false;
                    this.billList = refresh ? res.data.list : this.billList.concat(res.data.list)
                    !res.data.isLastPage && this.pageNum++;
                    res.data.isLastPage && (this.noDataText = '到底了');
                }
            }).catch(err => {
                this.$loading.hide();
                this.$toast.error(err.msg || '请求失败');
            });
        },
        refresh() {
            this.pageNum = 1;
            this.getData(true);
        },
        infinite() {
            if (!this.billList.length || this.isLast) {
                this.$refs.scroll.finishInfinite(true);
                return;
            }
            this.getData();
        },
        tofixed(v) {
            return (+v).toFixed(2)
        }
    }
}
</script>

<style lang="scss" scoped>
.bill-done-wrapper {
    background: #f5f5f5;
    height: 100%;
    .bill-done {
        padding: 12px 16px;
    }
    .bill-list-item {
        margin-bottom: 12px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .payed-icon {
        position: absolute;
        right: 3px;
        top: 4px;
        height: 50px;
        img {
            height: 100%;
            user-select: none;
        }
    }
}
</style>
